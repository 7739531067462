:root {
    --primary-dark : #3D3D3D;
    --primary-default : #696969;
    --primary-light :  #919191;
    --secondary-berry-dark  : #B33553;
    --secondary-berry-default : #C7506C;
    --secondary-berry-light : #EBC7D0;
    --secondary-berry-lighter : #F7E5E9;
    --secondary-bronze-dark : #9B896F;
    --secondary-bronze-default: #B6AA98;
    --secondary-bronze-light: #E5DED3;
    --secondary-bronze-lighter : #F1EFEB;
    --white : #fff;
    --grey-grey50: #F4F4F4;
    --grey-grey100: #E1E1E1;
    --grey-grey200: #CDCDCD;
    --grey-grey300: #B9B9B9;
    --grey-grey400: #A5A5A5;
    --grey-grey500: #919191;
    --grey-grey600:#7D7D7D;
    --grey-grey700:#696969;
    --grey-grey800: #555;
    --grey-grey900: #3D3D3D;
    --text-primary: #222;
    --text-secondary: #696969;
    --text-tertiary: #919191;
    --text--primaty--white : #FAFAFA;
  
  
  }
  
 
.CarouselDotBar{
    display: flex;
    padding: 2px 4px;
    justify-content: center;
    align-items: center;
  }
  
  .CarouselDot{
    display: flex;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: unset;
  }
  
  .Carousel{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .CarouselContainer{  
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
  }
  
  .CarouselContainer div {
    max-width: unset !important;
  }
  
  

  .VideoContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-end;
    overflow: 'hidden';
}

.VideoTimeStamp{
  position: relative;
  bottom:-35px;
  color:rgba(255,255,255,0.7);
  background: rgba(34, 34, 34, 0.70);
  border-radius: 4px;
  font-size: small;
  width: 45px;
  height: 25px;
  text-align: center;
  padding: 2px 4px;
  margin: 0px 5px; 
  margin-top: -25px;
}


.ActiveSlide{
    color: #9B896F;
  
  }


.ContentButton{
    border: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }


.DotWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .FloatingCarouselButtonCenter{
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: center;
    position: relative;
    border:none;
    border-radius: 99px;
    background: rgba(34, 34, 34, 0.70);
    /* FAB-shadow */
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
    margin-top: -60px;
  }

  .FloatingCarouselButtonCorner{
    display: flex;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    border:none;
    border-radius: 99px;
    background: rgba(34, 34, 34, 0.70);
    /* FAB-shadow */
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.15);
    margin-top: -35px;
    margin-right: 5px;
  }


  .ProgressBarContainer{
    width: 95%;
    align-self: center;
    position: relative;
    top:-1px;
    margin-top: -3px;
    height: 3px;
    background-color: rgba(61, 61, 61, 0.7);
    border-radius: 4px;
  }
  
  .ProgressBar{
    width: 100%;
    height: 3px;
    background-color: #919191;
    border-radius: 4px;
  }



  .UploadContentBox{
    display: flex;
    justify-content: center;
    height: 342px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .PhotoPlacement{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
    width: 260px;
  }

  .UploadButton{
    display: flex;
    height: 36px;
    min-width: 96px;
    padding: 0px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: var(--primary-default, #696969);
   
  }
  
  .UploadLabel{
    color: var(--white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: 0.56px;
  }
  
  .FloatingCarouselButton{
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    align-self: center;
    top: 400px;
    margin-right: 260px;
    border:none;
    border-radius: 99px;
    background: rgba(34, 34, 34, 0.70);
    /* FAB-shadow */
    box-shadow: 0px -2px 16px 0px rgb(182, 182, 182);
  
  }



  .ModalCenteredLayout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99; /* Ajusta el z-index según sea necesario */
  }
  
  .ModalCenteredBox {
    position: relative;
    max-width: 350px; 
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  
    display: flex;
    padding: 32px 32px 24px 32px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background-color: #FFF;
  }
  
  
  
  .ModalTitle{
    color: var(--primary-default, #696969);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%; /* 29.26px */
  }
  
  .ModalBody{
    color: var(--text-secondary, #696969);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
    letter-spacing: 0.42px;
  }



  .ModalButton{
    display: flex;
    height: 44px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    background: var(--secondary-bronze-default, #B6AA98);
    color: var(--white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.98px;
    text-transform: uppercase;
  }
  
  .ModalSecondaryButton{
    color: var(--secondary-bronze-dark, #9B896F);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.98px;
    text-transform: uppercase;
  }
  
  
  .ModalCloseButton{
    display: flex;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    margin-top: -48px;
    align-self:flex-end ;
    margin-right: -30px;
    top: 22px;
  }



.EditPhotoBox{
  background-color:#F4F4F4;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
