

.DurationTimeBox{
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 100%;
  margin: 5px 0px 20px 0px;
}


.css-ta95xa-MuiImageListItem-root{
  overflow: hidden;
}

.css-yfic6z{
  overflow: hidden;
}

.LocationBox {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 4px 12px;
  gap: -4px;
  background-color: rgba(0, 0, 0, 0.04);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

.LocationBox:hover {
  background-color: rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
}

.LocationInput{
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.LocationInput  > input {
  background: none !important;
  max-width: 700px;
  padding: unset !important;
  margin: unset !important;
  border: none !important;
  height: unset !important;

}

.LocationInput  > input:focus-visible {
  outline:  none !important;

}


.Title > span {
  font-size: 2rem !important;
  font-weight: bold;
}